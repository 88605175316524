import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PostImage } from "../utils/apiCall";
import { defaultConfig } from "../config";
import { LinearProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import { Dialog} from '@mui/material';

const Back = ({ t ,image,Back,classcss}) => {

  const navigate = useNavigate();
  return (
    <div className=" d-flex align-items-center justify-content-between">
      <Link
        onClick={() => {
          navigate(-1);
        }}
        className={`${classcss} backarrow d-flex align-items-center gap-2 p-3`}>
        <figure className="mb-0">
          <img src={image} className="img-fluid" alt="" />
        </figure>
        <span className="text-dark">{t(Back)}</span>
      </Link>
    </div>
  );
};

const Delete = ({ open, handleClose, handleDelete, title, id, loder }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    > */}

      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body logout-popup text-center">
            <span className="mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={48}
                height={48}
                viewBox="0 0 48 48"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.592 4.4881C25.306 3.6521 28 6.8441 28 10.0001V38.0001C28 41.1561 25.306 44.3481 21.592 43.5121C12.668 41.5041 6 33.5321 6 24.0001C6 14.4681 12.668 6.4961 21.592 4.4881ZM32.586 16.5861C32.9611 16.2112 33.4697 16.0005 34 16.0005C34.5303 16.0005 35.0389 16.2112 35.414 16.5861L41.414 22.5861C41.7889 22.9612 41.9996 23.4698 41.9996 24.0001C41.9996 24.5304 41.7889 25.039 41.414 25.4141L35.414 31.4141C35.0368 31.7784 34.5316 31.98 34.0072 31.9754C33.4828 31.9709 32.9812 31.7606 32.6104 31.3897C32.2395 31.0189 32.0292 30.5173 32.0247 29.9929C32.0201 29.4685 32.2217 28.9633 32.586 28.5861L35.172 26.0001H18C17.4696 26.0001 16.9609 25.7894 16.5858 25.4143C16.2107 25.0392 16 24.5305 16 24.0001C16 23.4697 16.2107 22.961 16.5858 22.5859C16.9609 22.2108 17.4696 22.0001 18 22.0001H35.172L32.586 19.4141C32.2111 19.039 32.0004 18.5304 32.0004 18.0001C32.0004 17.4698 32.2111 16.9612 32.586 16.5861Z"
                  fill="white"
                />
              </svg>
            </span>
            <h3>{CapitalizeFirstLatter(t("delete"))}</h3>
            <p>
              {t("Are your sure you want to")} {t("delete")}{" "}
              {title && CapitalizeFirstLatter(title)}?
            </p>
            <div className="save_btn">
              <button
                type="button"
                className="dashboard_btn_outline me-3"
                onClick={handleClose}>
                {t("No")}
              </button>
              <button
                type="button"
                className="dashboard_btn"
                disabled={loder}
                onClick={() => {
                  handleDelete(id);
                  handleClose();
                }}>
                {t("Yes")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* </Dialog> */}
    </>
  );
};

function CapitalizeLetter(string) {
  return string?.toUpperCase();
}

function debounce(method, pageNo, rowsPerPage, searchQuery, filterQuery) {
  const timer = setTimeout(() => {
    method(pageNo, rowsPerPage, searchQuery, filterQuery);
  }, 500);
  return () => {
    clearTimeout(timer);
  };
}

const ApiLoder = () => {
  return (
    <>
      <div className="spinner_overlay"></div>
      <div className="spinner-box">
        <img src="/image/loder.gif" alt="" className="loading-icon" />
      </div>
    </>
  );
};

const ImageApiLoder = () => {
  return (
    <>
    <div className="spinner_overlay"></div>
      <div className="spinner-box">
        <img src="/image/loder.gif" alt="" className="loading-icon" />
        <p style={{color:"white"}}>... this might take a sec</p>
      </div>
</>
 );
};

export const trimText = (value, size) => {
  let str = "";
  if (value && value.length > size) {
    if (value[size - 1] === " ") str = value.slice(0, size - 1) + "...";
    else str = value.slice(0, size) + "...";
  }
  return str;
};

const SeeMore = ({ value, size }) => {
  const [show, setShow] = useState(false);
  return value?.length > size ? (
    <>
      {show ? value : trimText(value, size)}
      <span
        style={{ fontWeight: "bold", cursor: "pointer" }}
        onClick={() => {
          setShow(!show);
        }}>
        {show ? " see less" : " see more"}
      </span>
    </>
  ) : (
    value
  );
};

const SuccessMessage = (message) => {
  setTimeout(
    () =>
      toast.success(message, {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }),
    10
  );
};

const ErrorMessage = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 2000,
    theme: "colored",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const acceptImageType = "image/png,image/jpg,image/jpeg";
const imageType = ["image/png", "image/jpg", "image/jpeg"];
const acceptDocOrPdfType = ".doc,.pdf";
const docOrPdfType = ["doc", "pdf"];
const acceptFileType =
  "image/png,image/jpg,image/jpeg,.doc, .docx,.xls,.xlsx,.pdf,.csv,";
const fileType = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "pdf",
  "csv",
  "jpg",
  "png",
  "jpeg",
];
const acceptVideoType = "mp4";
const videoType = ["mp4"];

function CapitalizeFirstLatter(s) {
  return s[0].toUpperCase() + s.slice(1);
}

const itemListArray = [
  { _id: 1, status: false, feedback: "" },
  { _id: 2, status: false, feedback: "" },
  { _id: 3, status: false, feedback: "" },
  { _id: 4, status: false, feedback: "" },
];

function cleanObject(obj) {
  for (const key in obj) {
    if (obj[key] === "") {
      delete obj[key]; // Remove empty string
    } else if (Array.isArray(obj[key]) && obj[key].length === 0) {
      delete obj[key]; // Remove empty array
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      cleanObject(obj[key]); // Recursively clean nested objects
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]; // Remove empty object
      }
    }
  }
  return obj;
}

function SubmitButton({ onClick, disabled, text, className }) {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={className ? className : "dashboard_btn"}>
      {text}
      {disabled && (
        <i className="fa fa-spinner fa-spin" style={{ marginLeft: "10px" }}></i>
      )}
    </button>
  );
}

const CommonUploadImage = async (e, handleonchange, allowedFileTypes) => {
  let file = e?.target?.files[0];
  let name = e?.target?.name;
  handleonchange((extra) => ({ ...extra, [name]: "progress" }));

  if (
    allowedFileTypes.includes(file?.type) ||
    allowedFileTypes.includes(file?.name?.split(".").reverse()[0])
  ) {
    const path = await PostImage(file);
    if (path?.length > 0) {
      handleonchange((extra) => ({ ...extra, [name]: path[0] }));
    } else {
      handleonchange((extra) => ({ ...extra, [name]: "" }));
    }
    e.target.value = null;
  } else {
    ErrorMessage("Invalid file Formate");
    handleonchange((extra) => ({ ...extra, [name]: "" }));
    e.target.value = null;
    return false;
  }
};

const ShowPreviewImage = ({ uploadFile, handleonchange, file_name }) => {
  const title = trimText(uploadFile, 10);
  let type = fileType.includes(uploadFile?.split(".").reverse()[0]);
  return (
    <>
      {uploadFile === "progress" ? <LinearProgress /> : ""}

      {uploadFile && type && uploadFile !== "progress" ? (
        <div className="col-lg-12 d-flex">
          <p className="image_msg">
            <span
              onClick={() => {
                removeImage(file_name, handleonchange);
              }}>
              X
            </span>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(defaultConfig.imagePath + uploadFile);
              }}>
              <i className="icofont-file-alt"></i>
              <label style={{ cursor: "pointer" }}>{title}</label>
            </div>
          </p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
const removeImage = async (name, handleonchange) => {
  handleonchange((val) => ({ ...val, [name]: "" }));
};

const BackButton = ({ url, classname }) => {
  const navigate = useNavigate();
  return (
    <Link
      onClick={() => {
        navigate(-1);
      }}
      className={classname}>
      <figure className="mb-0">
        <img src="/image/back.png" className="img-fluid" alt="" />
      </figure>
    </Link>
  );
};

const useAutoDisableBtn = (content) => {
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (content === "") {
      setDisable(true);
    } else setDisable(false);
  }, [content]);

  return [disable, setDisable];
};

export const useHandletoggle = () => {
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  }

  return [show, handleToggle]
};

const DistanceValue = ({ distance }) => {
  const distanceWithUnit = distance && `${Number(distance)?.toFixed(1)} mi,`;
  if (Number(distance) < 0.05)
    return (
      <label>
        <span className="text-danger">Here</span>,
      </label>
    );
  else return <label>{distanceWithUnit}</label>;
};

const LocationColor = ({ distance }) => {
  return Number(distance) > 0.5 ? (
    <img src="/image/redLoc.png" className="img-fluid" alt="" />
  ) : (
    <img src="/image/loc.png" className="img-fluid" alt="" />
  );
};

const DistanceTag = ({ distance }) => {
  return (
    <>
      <figure className="mb-0 flex-shrink-0">
        <LocationColor distance={distance} />
      </figure>
      <DistanceValue distance={distance} />
    </>
  );
};

function groupBy(array, key) {
  return array.reduce((acc, obj) => {
      const groupKey = obj[key];
      acc[groupKey] = acc[groupKey] || [];
      acc[groupKey].push(obj);
      return acc;
  }, {});
}

function nestedGroupBy(array, key1 ="", key2 = ""){
  return array.reduce((acc, obj) => {
    const groupKey = obj[key1][0]?.[key2];
    acc[groupKey] = acc[groupKey] || [];
    acc[groupKey].push(obj);
    return acc;
}, {})
}


export {
  Back,
  ApiLoder,
  debounce,
  SeeMore,
  Delete,
  ErrorMessage,
  SuccessMessage,
  CapitalizeLetter,
  useAutoDisableBtn,
  acceptImageType,
  imageType,
  acceptFileType,
  fileType,
  acceptVideoType,
  videoType,
  acceptDocOrPdfType,
  docOrPdfType,
  CapitalizeFirstLatter,
  cleanObject,
  SubmitButton,
  CommonUploadImage,
  ShowPreviewImage,
  BackButton,
  itemListArray,
  DistanceTag,
  groupBy,
  nestedGroupBy,
  ImageApiLoder
};
